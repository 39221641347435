export default [
  {
    title: "Dashboard",
    icon: "HomeIcon",
    route: "dashboard",
    action: "manage",
    resource: "dashboard",
  },
  {
    title: "Client",
    icon: "UmbrellaIcon",
    route: "client-list",
    action: "manage",
    resource: "client",
  },
  {
    title: "Work Order",
    icon: "MonitorIcon",
    route: "work-order",
    resource: "workorder",
  },
  {
    title: "Bait",
    icon: "PaperclipIcon",
    route: "bait-list",
    resource: "bait",
  },
  {
    title: "Agent",
    icon: "ZapIcon",
    route: "agent-list",
    resource: "agent",
  },
  {
    title: "Domain",
    icon: "TargetIcon",
    route: "domain-list",
    action: "read",
    resource: "domain",
  },
  {
    title: "user",
    icon: "UsersIcon",
    route: "user-list",
    action: "read",
    resource: "member",
  },
  {
    title: "report",
    icon: "FileIcon",
    resource: "report",
  },
];
